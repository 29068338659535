import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/app/www/theme/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "500px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.87116564417178%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFQABAQAAAAAAAAAAAAAAAAAAAAT/xAAVAQEBAAAAAAAAAAAAAAAAAAABAP/aAAwDAQACEAMQAAABrTVDSI//xAAcEAACAgIDAAAAAAAAAAAAAAABAgADBBMSIjL/2gAIAQEAAQUCroAnqzQpgflVir01JP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABwQAAICAgMAAAAAAAAAAAAAAAASARExoQJBUf/aAAgBAQAGPwJm0QtJ7ZmSujkY2f/EABoQAQEBAQEBAQAAAAAAAAAAAAERIQBxQcH/2gAIAQEAAT8hLsol2J2IM2PDoj48tZjJ0Kbv5yj8+I7/2gAMAwEAAgADAAAAEEs//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERIf/aAAgBAwEBPxDVy//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAgEBPxDEf//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBMXFRYZH/2gAIAQEAAT8QEhZjAdjOUIYzI8xahHWGFAA4N9wiKtXO/bzLsq2iPg1P/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "quiz",
          "title": "quiz",
          "src": "/static/b4ed0991f706bcb4db7c1eed0436879f/41099/quiz.jpg",
          "srcSet": ["/static/b4ed0991f706bcb4db7c1eed0436879f/d2f63/quiz.jpg 163w", "/static/b4ed0991f706bcb4db7c1eed0436879f/c989d/quiz.jpg 325w", "/static/b4ed0991f706bcb4db7c1eed0436879f/41099/quiz.jpg 500w"],
          "sizes": "(max-width: 500px) 100vw, 500px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <center>
      <p><em parentName="p">{`Ce billet est une transcription linéaire partielle d'un meetup qui s'est déroulé en Janvier 2020 à la `}<a parentName="em" {...{
            "href": "https://www.wildcodeschool.com/fr-FR/campus/toulouse"
          }}>{`WildCodeSchool`}</a>{` à Toulouse.`}</em>{` `}</p>
    </center>
    <br />
    <blockquote>
      <p parentName="blockquote">{`Pour chaque exercice il faut proposer une représentation visuelle qui:						`}</p>
      <ul parentName="blockquote">
        <li parentName="ul">{`répond à la question posée						`}</li>
        <li parentName="ul">{`respecte (ou pas) les bonnes pratiques
`}</li>
      </ul>
      <p parentName="blockquote">{`L'utilisation d'un outil est préférable, mais un croquis papier/crayon est acceptable.	`}</p>
      <p parentName="blockquote">{`Conseils: Attention aux unités, échelles, légendes, couleurs et titre, ne pas oublier qu'il n'y a pas une seule bonne solution.		`}</p>
      <p parentName="blockquote">{`Les exercices sont indépendants, il n'est pas indispensable de répondre à tout.	`}</p>
      <p parentName="blockquote">{`Il est permis et encouragé de représenter le résultat d'un calcul plutôt que les données brutes si c'est plus clair.`}</p>
      <p parentName="blockquote">{`Par exemple, % plutôt que valeur, écart entre deux valeurs successives plutôt que les valeurs, ...						`}</p>
    </blockquote>
    <br />
    <h1>{`Parts de marché`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "467px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "34.96932515337423%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAuklEQVQoz51RywqEMAzs//+YICLi2xY9eNCDguCTijqSgGVd9uAaGBImyTBpRZqmcBwHnueB6iAIEMcxkiRBGIaIoog56kspYds2fN/nTD3aJZ5mlFIQ+BHzPGNZFrwJUZYl2rbFNE3o+57FyJnrujyw77vBcRwmX/jmBVnP8xzbthlyXVfUdc2Cn8tPILIsQ1EUN9taa1RVdRN8fLJlWfwJ9LCXq6Zp+OxXgl3XYRgGUB7H0Ti86n8FT0kyGvztcLkfAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "partsseul",
          "title": "partsseul",
          "src": "/static/19bf8f7d516d171d34e22ab158005b8d/85ff8/partsseul.png",
          "srcSet": ["/static/19bf8f7d516d171d34e22ab158005b8d/222b7/partsseul.png 163w", "/static/19bf8f7d516d171d34e22ab158005b8d/ff46a/partsseul.png 325w", "/static/19bf8f7d516d171d34e22ab158005b8d/85ff8/partsseul.png 467w"],
          "sizes": "(max-width: 467px) 100vw, 467px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Apparemment facile. Un bon gros camembert devrait faire l'affaire. N'est ce pas de cette manière que les parts de marché sont représentées depuis la nuit des temps ? `}</p>
    <h3>{`Les différentes propositions des participants`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.25766871165644%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAAAsSAAALEgHS3X78AAADUElEQVQ4y31TbW8UVRSeL37wg/4CY+JvqErLkhRsipImJkBoBMH4iRJBJC5NRQoNhCakAcJL021K0iqSAFGktGn6ZlxQq0RK2+3udrvbfe2yu9152e3svOzM3DvzeGdrGz8YTvLknLnPuc8998y5nK5r38WisUQqmQqmUukw8+E087FoNJxJp8MriWQ47mIl7iLI1pYTieQMgNe5/zNN057gP5bL5bZik1AYQhGGXIZFLWiqurFuGDSdL1yOZzPe8OJcR3Il064qaiej3uaWwksTilJLNGzbJvlcjlgWITaxSEFWyKqvh6yODtOlBE+TiTgVBZHopmmTdQl8xwlM/XQe88ps7SBW3IdcKBj8gwlsVVWtVrfiCnUgDl3Ho94fMeVfgyStwTLNGvdyeoQut75Gfh5usa7MXDRUTYauVHdyi4GFnmg0+rSQfznO8iYzmewkIWQCtu0XNf1FfODa4henb4f6B38NhgJ/hyoVZV5V1WK5JFEh6rNnn3U5c/HntFaMaezmeF5oAeyTsoY203COWXqpTZaVk4zf7faYFwSfVBSTIw+fzf82G5vXNSXFuKOTv/jrTnk741+eOoOv2zvJV6e/wb69eyc4XVNHa3cwUsjkIiiUt2485goy/2i1NA1v13Xs7/4L2gb3ics1NGwLHDjbhyO3ps3mwyfgadgW4qSSfM+wWF9+f1PvveI1W25CNw0dosg/qAk6eDyzehX9tw6h+fhjLBQcV/Bzl/toz5700WsPcbC9B7t27URjY2OUE4XSmJtBh9owdqwbXSN2rQRKyfi/FV7NCnP8xKfNkYELg0uiBWmzHds9O3wffNzqr69/f+y9d+ummpqaBrlKRW7TDetOVUX/WiQy4BC9D3CG2J//LBQMeCyK8zrFObNqnWNCZxh6DdMcZv6usi79IBT4Oyx2cZfhHe5Vli+W3CT4F76Hzy+jrG6212Gxgw42TnK+nX2LG3Ooyh7OcZxWFp9lQ+1lL6DLtCzPpqAFDAArQPwG7j918GfMbQeFQww8CWTxVt1xlG++wZ7OxmBTS67nlOXlGb1SQSqdrg11Npu9tClYkuRvTaom1h9ceO6/PTJXInjB9kUYeAMojt4bL5b7uouCKBTZXrfMun8AbdgRrTNO3SMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "PosterPie",
          "title": "PosterPie",
          "src": "/static/54a2a1bc31050754f5d1aef10420906b/a6d36/PosterPie.png",
          "srcSet": ["/static/54a2a1bc31050754f5d1aef10420906b/222b7/PosterPie.png 163w", "/static/54a2a1bc31050754f5d1aef10420906b/ff46a/PosterPie.png 325w", "/static/54a2a1bc31050754f5d1aef10420906b/a6d36/PosterPie.png 650w", "/static/54a2a1bc31050754f5d1aef10420906b/e548f/PosterPie.png 975w", "/static/54a2a1bc31050754f5d1aef10420906b/3c492/PosterPie.png 1300w", "/static/54a2a1bc31050754f5d1aef10420906b/5edfd/PosterPie.png 2589w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Mais il y a un petit piège. Le lecteur attentif aura remarqué que la somme des revenus des sociétés A, B , C est inférieur au marché total. Le camembert est le TOUT qui se décompose en parties. Oublier une partie revient à tromper le lecteur. Il y a donc une catégorie `}<strong parentName="p">{`Autres`}</strong>{` qui vaut 27 et qui mérite sa tranche: solutions D ou F. Ou alors solutions A ou E sous forme de barres.`}</p>
    <h1>{`Evolution de parts de marché`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "22.699386503067483%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAAAsSAAALEgHS3X78AAAAm0lEQVQY05VQXQuEIBD0//9AMe1L60EyCgwisZrYhTt8OrqBxdnBmV0VWmt0XYe6rtE0Dfq+B2nOObRti6qq+CRNKQUpJXO6Tzp5jDHcky5QwFqLfd+ZL8sC7z3+hSDTcRxIKWEcR0zThJwz1nXlAfd947qu1yXmef6mxxixbRvz8zwRQmBOoWX90gS9mzYhDMPAm33C6W9L8xs83xiAwRpunD0AAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "partsevol",
          "title": "partsevol",
          "src": "/static/8ca931d81da44c1f84dfa78478bae7ac/a6d36/partsevol.png",
          "srcSet": ["/static/8ca931d81da44c1f84dfa78478bae7ac/222b7/partsevol.png 163w", "/static/8ca931d81da44c1f84dfa78478bae7ac/ff46a/partsevol.png 325w", "/static/8ca931d81da44c1f84dfa78478bae7ac/a6d36/partsevol.png 650w", "/static/8ca931d81da44c1f84dfa78478bae7ac/1ac66/partsevol.png 651w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Le cas est plus complexe. Bien sûr il est possible de tenter la juxtaposition de 2 camemberts (avant/après) mais on sent bien que cela ne doit pas être la meilleure solution. N'est ce pas ?`}</p>
    <h3>{`Les différentes propositions des participants`}</h3>
    <br />
    <p>{`Effectivement. Il est déjà difficile de jauger deux tranches d'un même camembert alors pensez donc, deux camemberts côte à côte.`}</p>
    <br />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "71.16564417177914%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsSAAALEgHS3X78AAADmElEQVQ4y11TXWgcVRSeR9EXwRcfFEWQioLok/FdxCII7UssFhGEvIjii7SlRiFWg1EIgrVg6g9daqpJE9duaCNpk2bHpk1Wu0mT3e7O7M7uzm53J7M7Pzt/92fuPd7ZBJEeONzDOZfz931H8vr9b1VFzVUrldVqVZMb9bosbFnXdVnTNLlYKMptrS63qmVZURS5UCjIFVWVNfG3olaySrl8q6yUZ2YvLT8gJRKG4RrsC8EYbNse2KIQ+EEAwDkUPAe29txAYwr3S4QQ7/fdrDBfkv7O5ZZct5/4EYoi0m63SZK77zrEMC0MgMmVy6+wkbG5uG5AnOQkmFDLdiihlMZCGI0GVWLGhqW7xeJaFEX/VQt8f/ByxoFSMrDDwjhrXpjgif3rn3nIrG7D6JlFyO3UxUfKYxzhJMYYPyTV67WxZl3PeJY56/vexV2zO0cidIniIG254Wmg7s319Q/hrbOlOEAQpJc3g8ur/4Q//S5Hqt4N/z+6aHZYcm07vdNQgxvljmGalum7lrmta4bh0qSjUYc4X0LuGGS+X4EmgnXhe1LoM0KfFvqoWPGoSLQiWhUKL0s85reBr8G1v8r7dVxobF2EzXYyNzovZnmMh/A6MPugGP75BEi92fyuYxgLnudN93q9WYHBrLnbXfQd52MpIHAS2qup4NrPP3S73jlC+z+aV85PaXc78yLl2JoKDx6bh4ffHb/+SIbDQy6CA4zSzv1ImxoG2qhdlXY794Y6dvRRzYvev71x47hSrb0nFnNC/HkiQvANRAWIWim3b+4ErH3WAxA96ZbfyC2zXqdDW9sqtSwHafkSxI3SnBRzMskEyBsrCrR2mtArant8o/gDgfEU1H6B7tRzPCwtgfLZCxA277BBHEcQEywUAe7XBG0QiMBViUE83lZMWEplaSmvg95wB5yiDI6KZzJXAjg+WYtuKhwPf0WgpNOE7akwjKhj20gQGtlOH/l+SDBCb4qEcIp5LgSa2u0WC451r23tXUowvTC/MrGeLWXy2eJMt6Smay3jzK7HXmQUHxpcgmB4iFDCbe46ji+AelzijA2J2KzQ6TCOLyBCZvzAemN58dYfRw9/AScPfg7qyDjQia8h3kgtDVBu1I8M9iLIz/fJvy/PSjyfH+ps34GSosDW5iZUq1UIiX0g/dv1kbcPf+ocefUTZeGd02rnxCnDyZybThLGmLwmrs4QI1ZQhKsY4xrnXCwHnvoXvzLBMB38ghMAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Evolution",
          "title": "Evolution",
          "src": "/static/1c913533e42f6a7e60808c2a7b108c62/a6d36/Evolution.png",
          "srcSet": ["/static/1c913533e42f6a7e60808c2a7b108c62/222b7/Evolution.png 163w", "/static/1c913533e42f6a7e60808c2a7b108c62/ff46a/Evolution.png 325w", "/static/1c913533e42f6a7e60808c2a7b108c62/a6d36/Evolution.png 650w", "/static/1c913533e42f6a7e60808c2a7b108c62/e548f/Evolution.png 975w", "/static/1c913533e42f6a7e60808c2a7b108c62/3c492/Evolution.png 1300w", "/static/1c913533e42f6a7e60808c2a7b108c62/af975/Evolution.png 2478w"],
          "sizes": "(max-width: 650px) 100vw, 650px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <p>{`Non la `}<strong parentName="p">{`bonne`}</strong>{` solution consiste à abandonner les camemberts et à représenter les évolutions de valeurs. Et quoi de mieux qu'un `}<strong parentName="p">{`diagramme de pentes`}</strong>{` (slope chart). Solutions proposées C, I ou B.`}</p>
    <h1>{`Pour aller plus loin`}</h1>
    <p>{`Il y a d'autres questions/ challenges qui ont été traitées lors de ce meetup. Contactez nous si vous êtes intéressé.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      